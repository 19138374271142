import { Bundle } from "~/types/InformativePages/Header/CategoryBundleBrand";

export default function useBundles() {
  const loading = ref(false);
  const bundles = ref<Bundle[]>([]);

  const sortBundles = async (sort: string) => {
    loading.value = true;
    try {
      const { res } = await useApi<{ data: Bundle[] }>(
        `/api/v1/bundles?sort=${sort}`,
      );
      if (res?.data) bundles.value = res.data;
    } catch (error) {
      console.log("Error Occured", error);
    } finally {
      loading.value = false;
    }
  };

  const getBundles = async () => {
    loading.value = true;
    try {
      const { res } = await useApi<{ data: Bundle[] }>(`/api/v1/bundles`);
      if (res?.data) bundles.value = res.data;
    } catch (error) {
      console.log("Error Occurred", error);
    } finally {
      loading.value = false;
    }
  };

  return {
    bundles,
    loading,
    getBundles,
    sortBundles,
  };
}
